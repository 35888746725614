'use strict';

import { local } from '@/utils/storage';

export default {
  namespaced: true,
  state: {
    tenant: local.get('TENANT'),
    token: local.get('TOKEN'),
    language: local.get('LANGUAGE'),
    user: null,
    permissions: null,
    menus: null,
    apps: null,
  },
  mutations: {
    setTenant(state, value) {
      local.set('TENANT', value);
      state.tenant = value;
    },
    setToken(state, value) {
      local.set('TOKEN', value);
      state.token = value;
    },
    setLanguage(state, value) {
      local.set('LANGUAGE', value);
      state.language = value;
    },
    setUser(state, value) {
      state.user = value;
    },
    setPermissions(state, value) {
      state.permissions = value;
    },
    setMenus(state, value) {
      state.menus = value;
    },
    setApps(state, value) {
      state.apps = value;
    },
  },
};
