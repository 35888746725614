'use strict';

import http from '@/utils/http';

export default {
  pageUrl: '/authority/user/page',
  getPage(code, params) {
    return http.get('/authority/user/page', { params, code });
  },
  add(code, data) {
    return http.post('/authority/user', data, { code });
  },
  update(code, data) {
    return http.put('/authority/user', data, { code });
  },
  delete(code, params) {
    return http.delete('/authority/user', { params, code });
  },
  avatar(code, data) {
    return http.put('/authority/user/avatar', data, { code });
  },
  getUserIdByRoleId(code, roleId) {
    return http.get(`/authority/role/user/${roleId}`, { code });
  },
  getMenus(params) {
    return http.get('/authority/menu/router', { params }).then(result =>
      result.map(item => {
        if (item.children) {
          return {
            ...item,
            name: item.menuKey,
            children: item.children.map(i => ({
              ...i,
              name: i.menuKey,
            })),
          };
        }
        return { ...item, name: item.menuKey };
      })
    );
  },
  getPermissions(params) {
    return http.get('/authority/resource/find', { params });
  },
  getApps(userId) {
    return http.get(`/authority/applicationClient/visible/${userId}`);
  },
  verifyEmailOrPhone(code, data) {
    return http.post('/authority/user/judgment/emailOrPhone', data, { code });
  },
};
