<template>
  <div class="menu-wrapper">
    <div class="main-menu">
      <router-link to="/">
        <img :src="logoUrl" alt="首页" :style="{ width: '100%' }" />
      </router-link>
      <ul class="iss-menu">
        <a-tooltip
          v-for="item of menuList"
          :key="item.path"
          :title="item.meta.title"
          placement="right"
        >
          <li
            :class="{ active: item.path === active }"
            @click="handleSelect(item)"
          >
            <component :is="icons[item.meta.icon]" />
          </li>
        </a-tooltip>
      </ul>
    </div>
    <div class="sub-menu">
      <div :style="{ display: fold ? 'none' : 'block' }">
        <div class="menu-title">
          <component :is="icons[menuItem.meta?.icon]" />
          <span>{{ menuItem.meta?.title }}</span>
        </div>
        <ul>
          <li
            v-for="item in menuItem.children"
            :key="item.path"
            :class="{ active: $route.path.includes(item.path) }"
            @click="handleSubSelect(item)"
          >
            {{ item.meta.title }}
          </li>
        </ul>
      </div>
    </div>
    <!--二级菜单显示/隐藏按钮-->
    <div class="sub-menu-icon" :style="{ left: fold ? '60px' : '227px' }">
      <component
        :is="icons[fold ? 'MenuUnfoldOutlined' : 'MenuFoldOutlined']"
        @click="changeFold()"
      />
    </div>
  </div>
</template>

<script>
import { Tooltip } from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';

export default {
  components: {
    ATooltip: Tooltip,
  },
  props: {
    fold: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      icons,
      logoUrl: process.env.VUE_APP_PROJECT_LOGO,
      active: '',
      menuList: [],
      menuItem: {},
    };
  },
  watch: {
    '$store.state.account.menus': {
      handler(vn, ov) {
        if (vn !== ov) {
          this.menuList = vn;
          this.menuItem = vn.find(i => i.path === this.active) || {};
        }
      },
    },
  },
  methods: {
    handleSelect(item) {
      this.menuItem = item;
      this.active = item.path;
      if (item.children) {
        this.fold && this.$emit('update:fold', false);
      } else {
        this.$emit('update:fold', true);
        this.$router.push(item.path);
      }
    },
    handleSubSelect(item) {
      switch (item.accessWay) {
        case 'link':
          window.open(item.component);
          break;
        case 'iframe':
          this.$router.push(
            `${item.path}?path=${encodeURIComponent(item.component)}`
          );
          break;
        default:
          this.$router.push(item.path);
          break;
      }
    },
    changeFold() {
      this.$emit('update:fold', !this.fold);
    },
  },
  created() {
    const { path } = this.$route;
    this.active = `/${path.split('/')[1]}`;
    this.menuList = this.$store.state.account.menus || [];
    this.menuItem = this.menuList?.find(i => i.path === this.active) || {};
  },
};
</script>

<style lang="less" scoped>
.menu-wrapper {
  display: flex;
  height: 100vh;
  .main-menu {
    width: 60px;
    box-shadow: 0 0 10px 0 rgba(104, 134, 251, 0.3);
    z-index: 10;
    .iss-menu {
      width: 60px;
      text-align: center;
      font-size: 22px;
      li {
        margin: 3px;
        height: 54px;
        line-height: 54px;
        border-radius: 8px;
        cursor: pointer;
        &:hover {
          color: @primary-color;
          background-color: @primary-2;
        }
        &.active {
          color: #fff;
          background-color: @primary-color;
        }
      }
    }
  }
  .sub-menu {
    width: 100%;
    transition: 0.2s;
    background-color: #f6f7fb;
    .menu-title {
      padding: 30px 20px;
      font-size: 20px;
      font-weight: bold;
      .anticon {
        margin-right: 15px;
      }
    }
    ul {
      li {
        padding-left: 17px;
        height: 42px;
        line-height: 42px;
        border-left: 3px solid #f6f7fb;
        cursor: pointer;
        &:hover {
          color: @primary-color;
          background-color: @primary-2;
        }
        &.active {
          background-color: #fff;
          border-left-color: @primary-color;
        }
      }
    }
  }
  .sub-menu-icon {
    position: absolute;
    padding: 0 5px;
    font-size: 20px;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    color: @primary-color;
    box-shadow: @box-shadow-base;
    transition: 0.2s;
    z-index: 11;
    cursor: pointer;
  }
}
</style>
