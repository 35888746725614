import antdLocale from 'ant-design-vue/es/locale/zh_CN';
import momentLocale from 'moment/locale/zh-cn';
import common from './common';
import login from './login';

const components = {
  momentName: 'zh-cn',
  momentLocale,
  antdLocale,
};

export default {
  message: '-',
  ...components,
  ...common,
  ...login,
};
