<template>
  <a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { ConfigProvider } from 'ant-design-vue';

export default {
  components: {
    AConfigProvider: ConfigProvider,
  },
  setup() {
    const store = useStore();
    const i18n = useI18n();
    return {
      locale: computed(
        () => i18n.getLocaleMessage(store.state.account.language).antdLocale
      ),
    };
  },
};
</script>
