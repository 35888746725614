<template>
  <a-layout prefixCls="ant-layout">
    <a-layout-sider
      :collapsed="fold"
      :collapsedWidth="60"
      :width="257"
      theme="light"
    >
      <iss-menu v-model:fold="fold" />
    </a-layout-sider>
    <a-layout prefixCls="ant-layout" class="iss-container">
      <a-layout-header prefixCls="ant-layout-header">
        <iss-header />
      </a-layout-header>
      <iss-breadcrumb v-model:fold="fold" />
      <a-layout-content prefixCls="ant-layout-content">
        <!-- <transition name="fade-transform" mode="out-in">
          <router-view />
        </transition> -->
        <router-view v-slot="{ Component }">
          <transition name="fade-transform">
            <component :is="Component" />
          </transition>
        </router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import { ref } from 'vue';
import { Layout } from 'ant-design-vue';
import IssHeader from './header';
import IssBreadcrumb from './breadcrumb';
import IssMenu from './menu';

export default {
  name: 'Layout',
  components: {
    ALayout: Layout,
    ALayoutSider: Layout.Sider,
    ALayoutHeader: Layout.Header,
    ALayoutContent: Layout.Content,
    IssHeader,
    IssBreadcrumb,
    IssMenu,
  },
  setup() {
    const fold = ref(true);
    return { fold };
  },
};
</script>

<style lang="less" scoped>
.iss-container {
  background: #fff;
  .ant-layout-header {
    height: 48px;
    padding: 0;
    line-height: 48px;
    background: #fff;
  }
  .ant-layout-content {
    padding: 10px;
    height: calc(100vh - 89px);
    overflow: auto;
  }
}
</style>
