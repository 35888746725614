<template>
  <a-dropdown v-bind="$props">
    <div>
      <a-avatar :src="avatar" :size="46" />
      <caret-down-outlined :style="{ marginLeft: '5px', fontSize: '14px' }" />
    </div>
    <template #overlay>
      <a-menu @click="handleClick">
        <a-menu-item-group :title="`您好：${name}`">
          <a-menu-item key="personal">
            <smile-outlined :style="{ fontSize: '15px' }" />
            <span>个人中心</span>
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item key="logout">
            <logout-outlined :style="{ fontSize: '15px' }" />
            <span>退出</span>
          </a-menu-item>
        </a-menu-item-group>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import { Avatar, Dropdown, Menu } from 'ant-design-vue';
import {
  CaretDownOutlined,
  LogoutOutlined,
  SmileOutlined,
} from '@ant-design/icons-vue';
import { local, session } from '@/utils/storage';

export default {
  name: 'User',
  components: {
    AAvatar: Avatar,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItemGroup: Menu.ItemGroup,
    AMenuItem: Menu.Item,
    AMenuDivider: Menu.Divider,
    CaretDownOutlined,
    SmileOutlined,
    LogoutOutlined,
  },
  computed: {
    avatar() {
      const user = this.$store.state.account.user;
      if (!user['avatar']) {
        return require(`@/assets/avatar/default.jpg`);
      } else {
        if (
          user['avatar'].startsWith('http://') ||
          user['avatar'].startsWith('https://')
        ) {
          return user['avatar'];
        } else {
          return require(`@/assets/avatar/${user.avatar}`);
        }
      }
    },
    name() {
      return this.$store.state.account.user.name;
    },
  },
  methods: {
    handleClick({ key }) {
      switch (key) {
        case 'personal':
          this.$router.push('/personal');
          break;
        case 'logout':
          local.clear();
          session.clear();
          location.reload();
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.avatar-wrapper {
  display: inline-flex;
  align-items: center;
  .user-name {
    margin-left: 5px;
    font-size: 16px;
  }
}
</style>
