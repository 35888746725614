<template>
  <div class="iss-header">
    <div class="header-left" />
    <div class="header-right">
      <!-- <iss-search class="right-item" /> -->
      <div class="right-item"><iss-fullscreen /></div>
      <!-- <div class="right-item"><iss-language /></div> -->
      <div class="right-item"><iss-user /></div>
    </div>
  </div>
</template>

<script>
// import IssSearch from './search';
import IssFullscreen from './fullscreen';
// import IssLanguage from './language';
import IssUser from './user';

export default {
  components: {
    // IssSearch,
    IssFullscreen,
    // IssLanguage,
    IssUser,
  },
};
</script>

<style lang="less" scoped>
.iss-header {
  display: flex;
  border-bottom: 1px solid #f1f1f1;
  .header-left {
    flex: 1;
  }
  .header-right {
    display: flex;
    font-size: 20px;
    color: #5a5e66;
    .right-item {
      padding: 0 8px;
      margin-left: 5px;
      cursor: pointer;
      &:hover {
        background-color: #eceef1;
      }
    }
  }
}
</style>
