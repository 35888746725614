<template>
  <div class="ant-breadcrumb iss-breadcrumb">
    <span class="ant-breadcrumb-link">当前位置</span>
    <span class="ant-breadcrumb-separator">:</span>
    <template v-if="first.meta">
      <a class="ant-breadcrumb-link" @click="$emit('update:fold', !fold)">
        <component :is="icons[first.meta.icon]" />
        <span>{{ first.meta.title }}</span>
      </a>
      <span class="ant-breadcrumb-separator">➞</span>
    </template>
    <template v-for="item in breadcrumbs" :key="item.path">
      <a class="ant-breadcrumb-link" @click="handleClick(item)">
        <component v-if="item.meta?.icon" :is="icons[item.meta.icon]" />
        <span>{{ item.meta?.title }}</span>
      </a>
      <span class="ant-breadcrumb-separator">➞</span>
    </template>
    <span class="ant-breadcrumb-link" v-if="last.meta">
      <component v-if="last.meta.icon" :is="icons[last.meta.icon]" />
      <span>{{ last.meta.title }}</span>
    </span>
  </div>
</template>

<script>
import { getCurrentInstance, reactive, toRaw, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import * as icons from '@ant-design/icons-vue';

export default {
  name: 'Breadcrumb',
  props: {
    fold: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const routes = router.getRoutes();
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const menus = store.state.account.menus;
    const bcs = reactive({ first: {}, breadcrumbs: [], last: {} });
    const analysis = list => {
      let datas = [];
      if (route.path.startsWith('/marketing/activity')) {
        if (list.length === 6 || list.length === 4) {
          const fullPath = route.path.split('/').slice(0, 4).join('/');
          datas = [{ ...routes.find(i => i.path === fullPath), fullPath }];
        }
      } else {
        datas = [
          {
            ...routes.find(i => i.path === list.join('/')),
            fullPath: route.path.split('/').slice(0, list.length).join('/'),
          },
        ];
        const next = list.slice(0, list.length - 1);
        next.length === 2 || (datas = datas.concat(analysis(next)));
      }
      return datas;
    };
    const getBreadcrumb = () => {
      let temp = [];
      const pathArr = route.matched[1].path.split('/');
      if (pathArr.length === 2) {
        bcs.first = {};
      } else {
        bcs.first = menus.find(i => i.path === `/${pathArr[1]}`);
        pathArr.length === 3 ||
          (temp = analysis(pathArr.slice(0, pathArr.length - 1)).reverse());
      }
      bcs.breadcrumbs = temp;
      bcs.last = { ...route };
    };
    watch(
      [() => route.path, () => store.state.common.breadcrumbs],
      ([path, breadcrumbs], [prevPath, prevBreadcrumbs]) => {
        if (
          !['login'].includes(route.name) &&
          !route.name.startsWith('microApp-') &&
          path !== prevPath
        ) {
          getBreadcrumb();
        } else if (
          breadcrumbs &&
          breadcrumbs.length !== prevBreadcrumbs?.length
        ) {
          // 处理主应用菜单
          const temp = breadcrumbs.concat();
          const pathArr = route.matched[1].path.split('/');
          if (pathArr.length === 3) {
            bcs.first = {};
          } else {
            bcs.first = menus.find(i => i.path === `/${pathArr[1]}`);
          }
          if (route.path.startsWith('/marketing/activity')) {
            const pArr = route.path.split('/');
            temp.unshift(
              {
                ...routes.find(i => i.path === pArr.slice(0, 4).join('/')),
                fullPath: pArr.slice(0, 4).join('/'),
              },
              {
                ...routes.find(i => i.path === pathArr.slice(0, 7).join('/')),
                fullPath: pArr.slice(0, 7).join('/'),
              }
            );
          }
          bcs.last = temp.pop();
          bcs.breadcrumbs = temp;
          document.title = `${bcs.last.meta.title} - ${process.env.VUE_APP_PROJECT_TITLE}`;
        }
      }
    );
    route.name.startsWith('microApp-') || getBreadcrumb();
    return {
      icons,
      ...toRefs(bcs),
      handleClick: item => {
        if (item.iframe) {
          proxy.$bus.emit('ISSIFRAMENAV', toRaw(item));
        } else {
          router.push(item.fullPath);
        }
      },
    };
  },
};
</script>

<style lang="less" scoped>
@import 'ant-design-vue/es/breadcrumb/style/index.less';

.iss-breadcrumb {
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #f1f1f1;
}
</style>
